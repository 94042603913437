/*
 * Shards v1.0.0 (https://designrevision.com/product/shards)
 * Based on: Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2017-* DesignRevision (https://designrevision.com)
 * Copyright 2017-* Catalin Vasile (http://catalin.me)
 */

// Core
@import "functions";
@import "variables";
@import "mixins";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";

//  Components
@import "icons";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "custom-sliders";
@import "custom-datepicker";
@import "utilities";
